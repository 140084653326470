import axios from "axios";
import { size } from "lodash";
import api from "../../utils/config";

export const setUploadFile = (data) => {
  return function (dispatch, getState) {
    dispatch({
      type: "SET_UPLOAD_FILE",
      payload: data,
    });
  };
};

export const modifyFiles = (existingFiles, files) => {
  let fileToUpload = {};
  for (let i = 0; i < files.file.length; i++) {
    const id = size(existingFiles) + i + 1;
    fileToUpload = {
      ...fileToUpload,
      [id]: {
        id,
        file: files.file[i],
        progress: 0,
        stock_id: files.stock,
      },
    };
  }

  return fileToUpload;
};

export const uploadFile = (files) => (dispatch) => {
  if (files.length) {
    files.forEach(async (file) => {
      const formPayload = new FormData();
      formPayload.append("file", file.file);
      formPayload.append("stock_id", file.stock_id);

      try {
        await axios({
          baseURL: api.API_URL,
          url: "/uploads",
          method: "post",
          data: formPayload,
          onUploadProgress: (progress) => {
            const { loaded, total } = progress;

            const percentageProgress = Math.floor((loaded / total) * 100);
            dispatch({
              type: "SET_UPLOAD_PROGRESS",
              payload: {
                id: file.id,
                progress: percentageProgress,
              },
            });
          },
        });
        dispatch({
          type: "SUCCESS_UPLOAD_FILE",
          payload: file.id,
        });
      } catch (error) {
        dispatch({
          type: "FAILURE_UPLOAD_FILE",
          payload: file.id,
        });
      }
    });
  }
};
