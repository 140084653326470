import axios from "axios";
import store from "../store/store";
import {
  setCurrentUser,
  removeCurrentUser,
} from "../store/actions/authActions";
import api from "./config";

export default function setAuthorizationToken() {
  if (localStorage.token) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.token}`;
    store.dispatch(setCurrentUser(localStorage.token));
  } else {
    delete axios.defaults.headers.common["Authorization"];
    store.dispatch(removeCurrentUser());
  }

  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      if (
        !localStorage.token &&
        config.url !== api.API_URL + "/auth/admin/login"
      ) {
        store.dispatch(removeCurrentUser());
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      const originalRequest = error.config;
      // console.log(originalRequest);
      if (error.response && error.response.status === 401) {
        store.dispatch(removeCurrentUser());
      }

      return Promise.reject(error);
    }
  );
}
