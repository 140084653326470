import { modifyFiles } from "../actions/FileUploadActions";

const iniState = { fileProgress: {} };

const FileUploadReducer = (state = iniState, action) => {
  switch (action.type) {
    case "SET_UPLOAD_FILE":
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          ...modifyFiles(state.fileProgress, action.payload),
        },
      };

    case "SET_UPLOAD_PROGRESS":
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            progress: action.payload.progress,
          },
        },
      };

    case "SUCCESS_UPLOAD_FILE":
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            status: 1,
          },
        },
      };

    case "FAILURE_UPLOAD_FILE":
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            status: 0,
            progress: 0,
          },
        },
      };

    default:
      return state;
  }
};

export default FileUploadReducer;
