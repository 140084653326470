import authReducer from "./authReducer";
import { combineReducers } from "redux";
import FileUploadReducer from "./FileUploadReducer";
import changeState from "./sideBarReducer";

const rootReducer = combineReducers({
  changeState: changeState,
  auth: authReducer,
  files: FileUploadReducer,
});

export default rootReducer;
