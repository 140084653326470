const GlobalVar = Object.freeze({
  API_URL: "https://api.pyramidsdiagnostics.com",
  PAY_STACK_KEY: "sk_test_0785e848cba6b76161735fb2e1827f564208d9cf",

  // API_URL: "http://localhost/pyramids2/api/public",

  // ... more of your variables
});

export default GlobalVar;
