import axios from "axios";
import setAuthorizationToken from "../../utils/TokenInterceptor";
import jwt from "jsonwebtoken";
import api from "../../utils/config";

export const signIn = (credentials) => {
  return (dispatch, getState) => {
    dispatch({ type: "AUTH_LOADING", loading: true });
    axios
      .post(api.API_URL + "/auth/admin/login", credentials)
      .then((user) => {
        const token = user.data.access_token;
        handleToken(token);
        dispatch(setCurrentUser(token));
      })
      .catch((err) => {
        var error;
        if (err.response) {
          error = err.response.data.error;
        } else error = "Error occured. Please try again";
        dispatch({ type: "LOGIN_ERROR", err: error });
      });
  };
};

const handleToken = (token) => {
  localStorage.setItem("token", token);
  setAuthorizationToken();
  // console.log(jwt.decode(token));
};

export const setCurrentUser = (user) => {
  return {
    type: "LOGIN_SUCCESS",
    payload: jwt.decode(user),
  };
};

export const removeCurrentUser = () => {
  localStorage.removeItem("token");
  return { type: "LOGOUT_SUCCESS" };
};

export const signOut = () => {
  return (dispatch, getState) => {
    axios
      .post(api.API_URL + "/auth/admin/logout", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then(() => {
        dispatch(removeCurrentUser());
      });
  };
};
